<template>
    <div class="h-screen grid justify-center items-center text-5xl font-bold dark:text-white px-10">
        We are working on some amazing ideas.
    </div>  
</template>

<script>
export default {
    name: "Solutions"

}
</script>

<style>

</style>